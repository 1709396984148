<template>
	<div
		data-component="lesson-resource-item-base"
		v-if="getShowResource"
	>
		<icon
			data-element="document-icon"
			icon="document"
			colour="grey"
		/>
		<a
			data-element="filename"
			:href="getUrl"
		>
			{{ getFilename }}
		</a>
		<slot />
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			resource: {
				type: Object,
				default: () => ({})
			}
		},
		computed: {
			getShowResource () {
				if (!this.getUrl ||
						!this.getFilename) {
					return false;
				}
				return true;
			},
			getUrl () {
				return this.resource.url;
			},
			getFilename () {
				return this.resource?.originalname;
			},
			getDescription () {
				return this.resource?.description;
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>
	[data-component='lesson-resource-item-base'] {
		display:flex;
		align-items:center;
		[data-element='document-icon'] {
			flex-shrink:0;
			width:rem(28);
			height:auto;
			margin-right:rem(8);
		}
		[data-element='filename'] {
			overflow:hidden;
			width:100%;
			white-space:nowrap;
			text-overflow:ellipsis;
			font-size:rem(15);
			color:$c-brand-blue;
			font-weight:700;
		}
	}
</style>
