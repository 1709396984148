<template>
	<div data-component="edit-bid-hints-modal">
		<div data-element="bids">
			<dynamic-table
				:columns="columns"
				:data="getBidHints"
				:activeRow="activeItem"
				max-height="376px"
			>
				<template #bid="{ row, index }">
					<bid-preview :bid="formatBid(row.bid, index)" />
				</template>
				<template #hint="{ cell }">
					<div
						data-element="hint-wrapper"
						v-html="cell"
					/>
				</template>
				<template #action="{ row, index }">
					<btn
						text="Edit"
						size="sm"
						@click.native="handleEdit(row, index)"
					/>
				</template>
			</dynamic-table>
		</div>
		<div
			v-if="getShowField"
			data-element="edit-hint"
		>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="handleSave"
			>
				<select-field
					v-if="getReusableItemsVisibility"
					name="Saved bid hints"
					searchable
					htmlOptions
					:searchIsLoading="reusableItemsLoading"
					:options="getReusableBidHintsOptions"
					@input="selectReusableBidHint"
					@searchChange="handleSearchChange"
				/>
				<wysiwyg-field
					name="Hint"
					type="text"
					:hasEditOptions="false"
					:hasListControl="false"
					:hasLinkControl="false"
					:value="getActiveItemHint"
					@input="handleHintInput"
				/>
				<actions
					:actions="getActions"
					:isWaiting="isWaiting"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import DynamicTable from '@/components/ui/views/dynamicTable/DynamicTable';
	import BidPreview from '@/components/dealLibrary/BidPreview';
	import Btn from '@/components/ui/Btn.vue';
	import Actions from '@/components/ui/Actions';
	import SelectField from '@/components/forms/SelectField';
	import api from '@/services/api';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import { Game } from '@bridge128/bridge128-game-logic-lib';
	import sanitizeHtml from 'sanitize-html';

	export default {
		components: {
			DynamicTable,
			BidPreview,
			Btn,
			Actions,
			SelectField,
			WysiwygField: () => import('@/components/forms/WysiwygField')
		},
		mixins: [actionClick, invalidFormMessage],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			columns: [
				{
					label: 'Bid',
					key: 'bid'
				},
				{
					label: 'Hint',
					key: 'hint'
				},
				{
					label: '',
					key: 'action'
				}
			],
			activeItem: undefined,
			isWaiting: undefined,
			reusableItemsLoading: false,
			reusableItemsSearch: '',
			reusableItems: []
		}),
		computed: {
			getDealData () {
				return this.deal?.deal;
			},
			getBids () {
				return this.getDealData?.auction.bids || [];
			},
			getAuctionValue () {
				return this.getDealData?.auction.value || '';
			},
			getPlayersOrder () {
				const playOrder = Game.PLAYORDER[this.getAuctionValue];
				if (playOrder) {
					const bidsLength = this.getBids.length;
					const orderLength = Math.ceil(bidsLength / playOrder.length);
					return Array(orderLength)
						.fill(playOrder)
						.flat()
						.slice(0, bidsLength);
				}
				return [];
			},
			getBidHints () {
				return this.deal?.bidHints || [];
			},
			getShowField () {
				return this.getBids[this.activeItem] !== undefined;
			},
			getActiveItemHint () {
				if (this.getShowField) {
					const bid = this.getBidHints[this.activeItem];
					return bid.hint;
				}
				return '';
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							btnId: 'save',
							type: 'submit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'handleEditCancel'
						}
					]
				};
			},
			getReusableItemsVisibility () {
				return this.reusableItems.length > 0;
			},
			getReusableBidHintsOptions () {
				return this.reusableItems
					.map(item => ({
						text: sanitizeHtml(item, {
							allowedTags: ['span'],
							allowedAttributes: {
								span: ['data-element', 'data-colour']
							}
						}),
						value: item
					}))
					.filter(({ text }) => text
						.toLowerCase()
						.includes(this.reusableItemsSearch.toLowerCase())
					);
			}
		},
		created () {
			this.fetchReusableBidHints();
		},
		methods: {
			close () {
				this.$emit('close');
			},
			getPlayerByIndex (index) {
				return this.getPlayersOrder[index];
			},
			formatBid (bid, index) {
				const name = bid;
				const isPass = bid === 'P';
				const isContractBid = !['P', 'X', 'XX'].includes(bid);
				const isDouble = bid === 'X';
				const value = isContractBid ? bid[0] : null;
				const suit = ['P', 'X', 'XX'].includes(bid) ? null : bid.slice(1);

				return {
					bid: name,
					type: isPass
						? 'pass'
						: isContractBid
							? 'contract'
							: isDouble
								? 'double'
								: 'redouble',
					value,
					suit,
					player: this.getPlayerByIndex(index)
				};
			},
			handleEdit (bid, index) {
				this.activeItem = undefined;
				this.$nextTick(() => {
					this.activeItem = index;
				});
			},
			handleEditCancel () {
				this.activeItem = undefined;
			},
			handleHintInput (value) {
				if (this.getShowField) {
					this.$set(this.deal.bidHints[this.activeItem], 'hint', value);
				}
			},
			selectReusableBidHint (value) {
				const currentActiveItem = this.activeItem;
				this.activeItem = undefined;
				this.reusableItemsSearch = '';
				this.$set(this.deal.bidHints[currentActiveItem], 'hint', value);
				this.$nextTick(() => {
					this.activeItem = currentActiveItem;
				});
			},
			handleSearchChange (query) {
				this.reusableItemsSearch = query;
			},
			async fetchReusableBidHints () {
				this.reusableItemsLoading = true;
				const response = await api.teacher.getTeacherBidHints();
				if (response) {
					this.reusableItems = response;
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.reusableItemsLoading = false;
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();

				}
				this.isWaiting = 'save';
				const deal = await api.deals.updateDeal({
					dealId: this.$route.params.dealId,
					deal: this.deal
				});
				if (deal) {
					this.deal.bidHints = deal.bidHints;
					this.$store.commit('ui/showNotification', {
						notification: 'The bid hints has been updated.'
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.isWaiting = undefined;
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='edit-bid-hints-modal'] {
		padding-bottom: rem(10);

		[data-element='bids'] {
			::v-deep [data-component='dynamic-table'] {
				[data-element='table-column-bid'] {
					width: 80px;
				}
				[data-element='table-column-action'] {
					width: 100px;

					[data-component='btn'] {
						width: fit-content;
						margin-left: auto;
					}
				}
				[data-element='table-column-hint'] {
					width: calc(100% - 180px);

					[data-element='hint-wrapper'] {
						@include rich-text-content;
						@include rich-text-suits;

						p {
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
		[data-element='edit-hint'] {
			[data-element='form'] {
				margin-bottom: 0;

				[data-component='select-field'] {
					margin-bottom: 0;
				}
				[data-component='actions'] {
					padding: 0;
				}
			}
		}
	}

</style>
