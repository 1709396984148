<template>
	<div
		data-component="opening-lead-select"
	>
		<select-field
			name="Opening lead"
			htmlOptions
			:selectLabel="''"
			:deselectLabel="''"
			:options="getOpeningLeadOptions"
			v-model="getOpeningLead"
		/>
	</div>
</template>

<script>

	import SelectField from '@/components/forms/SelectField';
	import { SEATS } from '@/consts';
	import { Game } from '@bridge128/bridge128-game-logic-lib';
	import { createSuitElement } from '@/helpers';

	export default {
		components: {
			SelectField
		},
		props: {
			value: {
				type: String,
				default: undefined
			},
			deal: {
				type: Object,
				required: true
			},
			excludeCards: {
				type: Array,
				default () {
					return [];
				}
			}
		},
		data: () => ({
			suitsDataMap: {
				H: {
					symbol: '♥',
					colour: 'red'
				},
				D: {
					symbol: '♦',
					colour: 'red'
				},
				S: {
					symbol: '♠',
					colour: 'black'
				},
				C: {
					symbol: '♣',
					colour: 'black'
				}
			},
			seats: SEATS
		}),
		computed: {
			getGameData () {
				if (this.deal) {
					return Game.fromState(this.deal.deal);
				}
				return null;
			},
			getOpeningLeadOptions () {
				if (this.getGameData) {
					const declarerSeat = this.getGameData.declarer;
					const openerSeat = this.seats[declarerSeat].left;
					const opener = this.getGameData[`player${openerSeat}`];
					return [
						{
							value: null,
							text: 'None'
						},
						...opener.handAsList.map(({ name }) => {
							const cardValue = name[0];
							const cardSuit = name[1];
							const suit = this.suitsDataMap[cardSuit];

							return {
								value: name,
								text: `<b>${cardValue}</b> ${createSuitElement(suit.symbol, suit.colour)}`
							};
						}).filter(card => !this.excludeCards.includes(card.value))
					];
				}
				return [];
			},
			getOpeningLead: {
				get () {
					return this.value;
				},
				set (newVal) {
					this.$emit('input', newVal);
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='opening-lead-select'] {
		[data-component='select-field'] {
			width: 140px;
		}
	}

</style>
