<template>
	<div data-component="edit-claim-parameters-modal">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="handleSave"
		>
			<select-field
				v-if="showField"
				name="Add parameter"
				searchable
				htmlOptions
				:options="getClaimParametersOptions"
				@input="selectClaimParameter"
			/>
			<div data-element="claim-parameters-list">
				<dynamic-table
					:columns="columns"
					:data="getClaimParameters"
					:activeRow="activeItem"
					overflow="visible"
				>
					<template #key="{ row }">
						<span v-html="getParameterText(row)" />
					</template>
					<template #value="{ row, cell, index }">
						<input-field
							v-if="getIsNumberValue(row.key)"
							type="number"
							:min="minTricks"
							:max="maxTricks"
							:showLabel="false"
							:name="row.key"
							:value="cell"
							@input="handleNumberInput($event, index)"
						/>
						<select-field
							v-else-if="getIsOptionsValue(row.key)"
							htmlOptions
							hideSelected
							multiple
							name="suit"
							:hasPlaceholder="false"
							:hasLabel="false"
							:selectLabel="''"
							:deselectLabel="''"
							:showNoOptions="false"
							:options="getSuitOptions"
							:value="cell"
							@input="handleSuitSelection($event, index)"
						/>
						<span v-else>{{ cell }}</span>
					</template>
					<template #action="{ row, index }">
						<btn
							text="Delete"
							colour="red"
							size="sm"
							@click.native="handleDelete(index)"
						/>
					</template>
				</dynamic-table>
			</div>
			<actions
				:actions="getActions"
				:isWaiting="isWaiting"
				@actionClick="onActionClick"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import SelectField from '@/components/forms/SelectField';
	import InputField from '@/components/forms/InputField';
	import DynamicTable from '@/components/ui/views/dynamicTable/DynamicTable';
	import Btn from '@/components/ui/Btn.vue';
	import Actions from '@/components/ui/Actions';
	import api from '@/services/api';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import { suitOptions } from '@/consts';
	import { createSuitElement } from '@/helpers';

	export default {
		components: {
			SelectField,
			InputField,
			DynamicTable,
			Btn,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			isWaiting: undefined,
			showField: true,
			claimParametersOptions: [
				{
					value: 'after_trick_is_played',
					valueType: 'number',
					text: 'After trick is played',
					maxCount: 1
				},
				{
					value: 'after_tricks_won',
					valueType: 'number',
					text: 'After tricks have been won by student',
					maxCount: 1
				},
				{
					value: 'opponent_no_cards_of_suit',
					valueType: 'options',
					text: 'If the opponent team has no cards of suits left',
					maxCount: 1
				}
			],
			columns: [
				{
					label: 'Parameter',
					key: 'key'
				},
				{
					label: 'Value',
					key: 'value'
				},
				{
					label: '',
					key: 'action'
				}
			],
			activeItem: undefined,
			minTricks: 0,
			maxTricks: 13
		}),
		computed: {
			getClaimParameters () {
				return this.deal.claimParameters || [];
			},
			getClaimParametersKeys () {
				return this.getClaimParameters.map(item => item.key);
			},
			getClaimParametersOptions () {
				return this.claimParametersOptions.filter(({ value, maxCount }) => {
					const count = this.getClaimParametersKeys.filter(key => key === value).length;
					return count < maxCount;
				});
			},
			getSuitOptions () {
				return suitOptions.map(option => {
					return {
						value: option.value,
						text: createSuitElement(option.symbol, option.colour)
					};
				});
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							btnId: 'save',
							type: 'submit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'close'
						}
					]
				};
			}
		},
		methods: {
			close () {
				this.$emit('close');
			},
			getParameterText (parameter) {
				const option = this.claimParametersOptions.find(item => item.value === parameter.key);
				if (option) {
					return option.text;
				}
				return parameter.key;
			},
			getParameterValueType (key) {
				const option = this.claimParametersOptions.find(item => item.value === key);
				return option?.valueType;
			},
			getIsNumberValue (key) {
				return this.getParameterValueType(key) === 'number';
			},
			getIsOptionsValue (key) {
				return this.getParameterValueType(key) === 'options';
			},
			selectClaimParameter (key) {
				this.showField = false;
				const value = this.getIsNumberValue(key) ? 0 : [];
				this.deal.claimParameters = [
					...this.getClaimParameters,
					{
						key,
						value
					}
				];
				this.$nextTick(() => {
					this.showField = true;
				});
			},
			handleNumberInput (value, index) {
				const parsedValue = parseInt(value);
				this.$set(
					this.deal.claimParameters[index],
					'value',
					isNaN(parsedValue) ? 0 : parsedValue
				);
			},
			handleSuitSelection (value, index) {
				this.$set(this.deal.claimParameters[index], 'value', value);
			},
			handleDelete (index) {
				this.deal.claimParameters = this.getClaimParameters.filter((_, i) => i !== index);
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();

				}
				this.isWaiting = 'save';
				const deal = await api.deals.updateDeal({
					dealId: this.$route.params.dealId,
					deal: this.deal
				});
				if (deal) {
					this.deal.claimParameters = deal.claimParameters;
					this.$store.commit('ui/showNotification', {
						notification: 'The claim parameters has been updated.'
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.isWaiting = undefined;
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='edit-claim-parameters-modal'] {
		padding-bottom: rem(10);

		[data-element='form'] {
			margin-bottom: 0;

			[data-component='select-field'] {
				margin-top: 0;
			}

			[data-element='claim-parameters-list'] {
				margin-bottom: rem(28);

				::v-deep [data-component='dynamic-table'] {
					[data-element='table-column-key'] {
						@include rich-text-suits;

						& > span {
							white-space: normal;
						}
					}

					[data-element='table-column-value'] {
						width: 310px;

						[data-component='input-field'] {
							width: 80px;
						}

						[data-component='input-field'],
						[data-component='select-field'] {
							margin: 0;
						}
					}

					[data-element='table-column-action'] {
						width: 120px;

						[data-component='btn'] {
							width: fit-content;
							margin-left: auto;
						}
					}
				}
			}

			[data-component='actions'] {
				padding: 0;
			}
		}
	}

</style>
