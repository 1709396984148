<template>
	<div data-component="list-view">
		<div data-element="rows">
			<list-view-row
				v-for="(row, key) in list"
				:key="key"
				:dense="getRowDensity(row)"
				v-bind="row"
				@actionClick="onActionClick"
			>
				<div data-element="row-content">
					{{ getText(row) }}
					<icon
						v-if="getIcon(row)"
						:icon="getIcon(row)"
						:colour="getIconColor(row)"
					/>
					{{ getSecondaryText(row) }}
				</div>
			</list-view-row>
		</div>
		<actions
			:actions="actions"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import ListViewRow from '@/components/ui/views/listView/ListViewRow';
	import Icon from '@/components/ui/Icon';
	import Actions from '@/components/ui/Actions';

	export default {
		components: {
			ListViewRow,
			Icon,
			Actions
		},
		props: {
			list: {
				type: [Array, Boolean],
				default: () => []
			},
			actions: {
				type: Object,
				default: () => {}
			}
		},
		methods: {
			getText (row) {
				if (!row.text) {
					return '';
				}
				return row.text;
			},
			getIcon (row) {
				if (row.icon) {
					return row.icon;
				}
				return false;
			},
			getIconColor (row) {
				if (this.getIcon(row) && row.iconColor) {
					return row.iconColor;
				}
				return '';
			},
			getSecondaryText (row) {
				if (!row.secondary) {
					return '';
				}
				return row.secondary;
			},
			getRowDensity (row) {
				return !!this.getIcon(row);
			},
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='list-view'] {
		[data-element='rows'] {
			[data-element='row-content'] {
				display: flex;
				align-items: center;
				[data-component='icon'] {
					width: rem(28);
					height: rem(28);
					margin: auto rem(6);
				}
			}
		}
  }

</style>
