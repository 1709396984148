var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-component":"edit-claim-parameters-modal"}},[_c('validation-observer',{ref:"form",attrs:{"data-element":"form","tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSave($event)}}},[(_vm.showField)?_c('select-field',{attrs:{"name":"Add parameter","searchable":"","htmlOptions":"","options":_vm.getClaimParametersOptions},on:{"input":_vm.selectClaimParameter}}):_vm._e(),_c('div',{attrs:{"data-element":"claim-parameters-list"}},[_c('dynamic-table',{attrs:{"columns":_vm.columns,"data":_vm.getClaimParameters,"activeRow":_vm.activeItem,"overflow":"visible"},scopedSlots:_vm._u([{key:"key",fn:function(ref){
var row = ref.row;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getParameterText(row))}})]}},{key:"value",fn:function(ref){
var row = ref.row;
var cell = ref.cell;
var index = ref.index;
return [(_vm.getIsNumberValue(row.key))?_c('input-field',{attrs:{"type":"number","min":_vm.minTricks,"max":_vm.maxTricks,"showLabel":false,"name":row.key,"value":cell},on:{"input":function($event){return _vm.handleNumberInput($event, index)}}}):(_vm.getIsOptionsValue(row.key))?_c('select-field',{attrs:{"htmlOptions":"","hideSelected":"","multiple":"","name":"suit","hasPlaceholder":false,"hasLabel":false,"selectLabel":'',"deselectLabel":'',"showNoOptions":false,"options":_vm.getSuitOptions,"value":cell},on:{"input":function($event){return _vm.handleSuitSelection($event, index)}}}):_c('span',[_vm._v(_vm._s(cell))])]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('btn',{attrs:{"text":"Delete","colour":"red","size":"sm"},nativeOn:{"click":function($event){return _vm.handleDelete(index)}}})]}}])})],1),_c('actions',{attrs:{"actions":_vm.getActions,"isWaiting":_vm.isWaiting},on:{"actionClick":_vm.onActionClick}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }