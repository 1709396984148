var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-component":"edit-bot-cards-modal"}},[_c('div',{attrs:{"data-element":"form"}},[_c('opening-lead-select-field',{attrs:{"deal":_vm.deal,"excludeCards":_vm.getExcludedCardsForOpeningLead},model:{value:(_vm.openingLead),callback:function ($$v) {_vm.openingLead=$$v},expression:"openingLead"}})],1),_c('div',{attrs:{"data-element":"cards-list"}},[_c('div',{attrs:{"data-element":"table-label"}},[_vm._v(" Cards to be played until student wins their first trick ")]),_c('validation-observer',{ref:"form",attrs:{"data-element":"form","tag":"form"}},[_c('dynamic-table',{attrs:{"columns":_vm.getColumns,"data":_vm.getTricks,"overflow":"visible"},scopedSlots:_vm._u([{key:"trick",fn:function(ref){
var index = ref.index;
return [_c('b',[_vm._v(_vm._s(index + 1))])]}},{key:"side",fn:function(ref){
var row = ref.row;
var column = ref.column;
var index = ref.index;
return [_c('card-select-field',{attrs:{"renderOnChange":"","id":_vm.getId(index, column),"rules":_vm.getRules(row, column, index),"options":_vm.getCardsOptionsWithText(column, index),"disabled":_vm.getCardSelectionDisabled(index, column),"value":_vm.getCardForSide(row, column)},on:{"input":function($event){return _vm.handleCardSelection($event, index, column)}}})]}},{key:"winner",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('b',[_vm._v(_vm._s(_vm.getWinnerSide(row, index)))])]}},{key:"action",fn:function(ref){
var index = ref.index;
return [_c('div',{attrs:{"data-element":"actions-cell"}},[(_vm.getCanClear(index))?_c('btn',{attrs:{"text":"Clear","size":"sm"},nativeOn:{"click":function($event){return _vm.handleClear(index)}}}):_vm._e(),(_vm.getCanDelete(index))?_c('btn',{attrs:{"text":"Delete","colour":"red","size":"sm"},nativeOn:{"click":function($event){return _vm.handleDelete(index)}}}):_vm._e()],1)]}}])})],1)],1),_c('actions',{attrs:{"actions":_vm.getActions,"isWaiting":_vm.isWaiting,"isDisabled":_vm.getIsDisabled},on:{"actionClick":_vm.onActionClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }