var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-component":"edit-bid-explanations-modal"}},[_c('div',{attrs:{"data-element":"bids"}},[_c('dynamic-table',{attrs:{"columns":_vm.columns,"data":_vm.getBidExplanations,"activeRow":_vm.activeItem,"max-height":"376px"},scopedSlots:_vm._u([{key:"player",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.getPlayerByIndex(index))+" ")]}},{key:"bid",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('bid-preview',{attrs:{"bid":_vm.formatBid(row.bid, index)}})]}},{key:"explanation",fn:function(ref){
var cell = ref.cell;
return [_c('div',{attrs:{"data-element":"explanation-wrapper"},domProps:{"innerHTML":_vm._s(cell)}})]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('btn',{attrs:{"text":"Edit","size":"sm"},nativeOn:{"click":function($event){return _vm.handleEdit(row, index)}}})]}}])})],1),(_vm.getShowField)?_c('div',{attrs:{"data-element":"edit-explanation"}},[_c('validation-observer',{ref:"form",attrs:{"data-element":"form","tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSave($event)}}},[(_vm.getReusableItemsVisibility)?_c('select-field',{attrs:{"name":"Saved bid explanations","searchable":"","htmlOptions":"","searchIsLoading":_vm.reusableItemsLoading,"options":_vm.getReusableBidExplanationsOptions},on:{"input":_vm.selectReusableBidExplanation,"searchChange":_vm.handleSearchChange}}):_vm._e(),_c('wysiwyg-field',{attrs:{"name":"Explanation","type":"text","hasEditOptions":false,"hasListControl":false,"hasLinkControl":false,"value":_vm.getActiveItemExplanation},on:{"input":_vm.handleExplanationInput}}),_c('actions',{attrs:{"actions":_vm.getActions,"isWaiting":_vm.isWaiting},on:{"actionClick":_vm.onActionClick}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }