<template>
	<div data-component="edit-declarer-hint-modal">
		<div
			v-if="showField"
			data-element="edit-hint"
		>
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="handleSave"
			>
				<select-field
					v-if="getReusableItemsVisibility"
					name="Saved declarer hints"
					searchable
					htmlOptions
					:searchIsLoading="reusableItemsLoading"
					:options="getReusableBidHintsOptions"
					@input="selectReusableBidHint"
					@searchChange="handleSearchChange"
				/>
				<wysiwyg-field
					name="Declarer hint"
					type="text"
					:hasEditOptions="false"
					:hasListControl="false"
					:hasLinkControl="false"
					:value="deal.declarerHint"
					@input="handleHintInput"
				/>
				<actions
					:actions="getActions"
					:isWaiting="isWaiting"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import Actions from '@/components/ui/Actions';
	import SelectField from '@/components/forms/SelectField';
	import api from '@/services/api';
	import actionClick from '@/mixins/actionClick';
	import invalidFormMessage from '@/mixins/invalidFormMessage';
	import sanitizeHtml from 'sanitize-html';

	export default {
		components: {
			Actions,
			SelectField,
			WysiwygField: () => import('@/components/forms/WysiwygField')
		},
		mixins: [actionClick, invalidFormMessage],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			isWaiting: undefined,
			reusableItemsLoading: false,
			reusableItemsSearch: '',
			reusableItems: [],
			showField: true
		}),
		computed: {
			getDealData () {
				return this.deal?.deal;
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Save',
							btnId: 'save',
							type: 'submit'
						}
					],
					secondary: [
						{
							text: 'Cancel',
							actionId: 'close'
						}
					]
				};
			},
			getReusableItemsVisibility () {
				return this.reusableItems.length > 0;
			},
			getReusableBidHintsOptions () {
				return this.reusableItems
					.map(item => ({
						text: sanitizeHtml(item, {
							allowedTags: ['span'],
							allowedAttributes: {
								span: ['data-element', 'data-colour']
							}
						}),
						value: item
					}))
					.filter(({ text }) => text
						.toLowerCase()
						.includes(this.reusableItemsSearch.toLowerCase())
					);
			}
		},
		created () {
			this.fetchReusableDeclarerHints();
		},
		methods: {
			close () {
				this.$emit('close');
			},
			handleHintInput (value) {
				this.deal.declarerHint = value;
			},
			selectReusableBidHint (value) {
				this.reusableItemsSearch = '';
				this.deal.declarerHint = value;
				this.showField = false;
				this.$nextTick(() => {
					this.showField = true;
				});
			},
			handleSearchChange (query) {
				this.reusableItemsSearch = query;
			},
			async fetchReusableDeclarerHints () {
				this.reusableItemsLoading = true;
				const response = await api.teacher.getTeacherDeclarerHints();
				if (response) {
					this.reusableItems = response;
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.reusableItemsLoading = false;
			},
			async handleSave () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
				}
				this.isWaiting = 'save';
				const deal = await api.deals.updateDeal({
					dealId: this.$route.params.dealId,
					deal: this.deal
				});
				if (deal) {
					this.deal.bidHints = deal.bidHints;
					this.$store.commit('ui/showNotification', {
						notification: 'The declarer hint has been updated.'
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
				this.isWaiting = undefined;
			}
		}
	};
</script>

<style lang="scss" scoped>

	[data-component='edit-declarer-hint-modal'] {
		padding-bottom: rem(10);

		[data-element='edit-hint'] {
			[data-element='form'] {
				margin-bottom: 0;

				[data-component='select-field'] {
					margin-top: 0;
					margin-bottom: 0;
				}

				[data-component='actions'] {
					padding: 0;
				}
			}
		}
	}

</style>
