<template>
	<div data-component="deal-parameters">
		<actions
			:actions="getActions"
			:isWrapped="true"
			@actionClick="onActionClick"
		/>
		<template v-if="deal">
			<portal
				to="modal"
				v-if="editBidExplanationsIsVisible"
			>
				<modal-inner
					leftAligned
					@close="toggleBidExplanations"
					heading="Edit bid explanations"
				>
					<edit-bid-explanations-modal
						:deal="deal"
						@close="toggleBidExplanations"
					/>
				</modal-inner>
			</portal>
			<portal
				to="modal"
				v-if="editBidHintsIsVisible"
			>
				<modal-inner
					leftAligned
					@close="toggleBidHints"
					heading="Edit user bid hints"
				>
					<edit-bid-hints-modal
						:deal="deal"
						@close="toggleBidHints"
					/>
				</modal-inner>
			</portal>
			<portal
				to="modal"
				v-if="editDeclarerHintIsVisible"
			>
				<modal-inner
					leftAligned
					@close="toggleDeclarerHint"
					heading="Edit declarer hint"
				>
					<edit-declarer-hint-modal
						:deal="deal"
						@close="toggleDeclarerHint"
					/>
				</modal-inner>
			</portal>
			<portal
				to="modal"
				v-if="editClaimParametersIsVisible"
			>
				<modal-inner
					leftAligned
					@close="toggleClaimParameters"
					heading="Edit claim parameters"
				>
					<edit-claim-parameters-modal
						:deal="deal"
						@close="toggleClaimParameters"
					/>
				</modal-inner>
			</portal>
			<portal
				to="modal"
				v-if="editSummaryMessagesIsVisible"
			>
				<modal-inner
					leftAligned
					@close="toggleSummaryMessages"
					heading="Edit summary messages"
				>
					<edit-summary-messages-modal
						:deal="deal"
						@close="toggleSummaryMessages"
					/>
				</modal-inner>
			</portal>
			<portal
				to="modal"
				v-if="editBotCardsIsVisible"
			>
				<modal-inner
					leftAligned
					@close="toggleBotCards"
					heading="Edit bot cards"
				>
					<edit-bot-cards-modal
						:deal="deal"
						@close="toggleBotCards"
					/>
				</modal-inner>
			</portal>
		</template>
	</div>
</template>

<script>

	import Actions from '@/components/ui/Actions';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import EditBidExplanationsModal from '@/components/dealLibrary/EditBidExplanationsModal';
	import EditBidHintsModal from '@/components/dealLibrary/EditBidHintsModal';
	import EditDeclarerHintModal from '@/components/dealLibrary/EditDeclarerHintModal';
	import EditClaimParametersModal from '@/components/dealLibrary/EditClaimParametersModal';
	import EditSummaryMessagesModal from '@/components/dealLibrary/EditSummaryMessagesModal';
	import EditBotCardsModal from '@/components/dealLibrary/EditBotCardsModal';
	import actionClick from '@/mixins/actionClick';

	export default {
		components: {
			EditSummaryMessagesModal,
			Actions,
			ModalInner,
			EditBidExplanationsModal,
			EditBidHintsModal,
			EditDeclarerHintModal,
			EditClaimParametersModal,
			EditBotCardsModal
		},
		mixins: [actionClick],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			editBidExplanationsIsVisible: false,
			editBidHintsIsVisible: false,
			editDeclarerHintIsVisible: false,
			editClaimParametersIsVisible: false,
			editSummaryMessagesIsVisible: false,
			editBotCardsIsVisible: false
		}),
		computed: {
			getActions () {
				return {
					primary: [
						{
							text: 'Edit bid explanations',
							actionId: 'toggleBidExplanations'
						},
						{
							text: 'Edit bid hints',
							actionId: 'toggleBidHints'
						},
						{
							text: 'Edit bot cards',
							actionId: 'toggleBotCards'
						},
						{
							text: 'Edit declarer hint',
							actionId: 'toggleDeclarerHint'
						},
						{
							text: 'Edit claim parameters',
							actionId: 'toggleClaimParameters'
						},
						{
							text: 'Edit summary messages',
							actionId: 'toggleSummaryMessages'
						}
					].filter(Boolean)
				};
			}
		},
		watch: {},
		created () {},
		methods: {
			toggleBidExplanations () {
				this.editBidExplanationsIsVisible = !this.editBidExplanationsIsVisible;
			},
			toggleBidHints () {
				this.editBidHintsIsVisible = !this.editBidHintsIsVisible;
			},
			toggleDeclarerHint () {
				this.editDeclarerHintIsVisible = !this.editDeclarerHintIsVisible;
			},
			toggleClaimParameters () {
				this.editClaimParametersIsVisible = !this.editClaimParametersIsVisible;
			},
			toggleSummaryMessages () {
				this.editSummaryMessagesIsVisible = !this.editSummaryMessagesIsVisible;
			},
			toggleBotCards () {
				this.editBotCardsIsVisible = !this.editBotCardsIsVisible;
			}
		}
	};

</script>

<style lang="scss" scoped>

</style>
