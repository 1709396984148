<template>
	<div
		data-component="bid-preview"
		:data-player="bid.player"
	>
		<div data-element="value">
			{{ getValue }}
		</div>
		<icon
			v-if="getHasIcon"
			:icon="getSuit"
			:colour="getSuitColour"
		/>
	</div>
</template>

<script>

	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			Icon
		},
		props: {
			bid: {
				type: Object,
				required: true
			}
		},
		data: () => ({}),
		computed: {
			getValue () {
				const bid = this.bid;
				if (bid.type === 'pass') {
					return 'p';
				}
				if (bid.type === 'double') {
					return 'X';
				}
				if (bid.type === 'redouble') {
					return 'XX';
				}
				if (bid.suit === 'NT') {
					return bid.value + 'NT';
				}
				return bid.value;
			},
			getHasIcon () {
				return !(this.getValue === 'p' ||
					this.getValue === 'X' ||
					this.getValue === 'XX');
			},
			getSuit () {
				const bid = this.bid;
				if (!bid.suit) {
					return false;
				}
				if (bid.suit === 'NT') {
					return false;
				}
				if (bid.suit === 'S') {
					return 'spade';
				}
				if (bid.suit === 'D') {
					return 'diamond';
				}
				if (bid.suit === 'C') {
					return 'club';
				}
				return 'heart';
			},
			getSuitColour () {
				const bid = this.bid;
				if (!bid.suit ||
					bid.suit === 'NT') {
					return '';
				}
				if (bid.suit === 'C' ||
					bid.suit === 'S') {
					return 'black';
				}
				return 'red';
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	[data-component='bid-preview'] {
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(36);
		height: rem(28);
		padding: rem(5);
		[data-component='icon'] {
			flex-shrink: 0;
			width: auto;
			height: rem(15);
		}
		[data-element='value'] {
			font-weight: 700;
			font-size: rem(18);
			text-transform: uppercase;
		}
	}

</style>
