<template>
	<div
		data-component="card-select"
	>
		<select-field
			v-if="showField"
			htmlOptions
			:name="name"
			:id="id"
			:rules="rules"
			:hasPlaceholder="false"
			:hasLabel="false"
			:selectLabel="''"
			:deselectLabel="''"
			:emptyAllowed="false"
			:showNoOptions="false"
			:disabled="disabled"
			:options="options"
			v-model="getCard"
		/>
	</div>
</template>

<script>

	import SelectField from '@/components/forms/SelectField';

	export default {
		components: {
			SelectField
		},
		props: {
			name: {
				type: String,
				default: 'Card'
			},
			id: {
				type: String,
				default: undefined
			},
			value: {
				type: String,
				default: undefined
			},
			rules: {
				type: [
					String,
					Object
				],
				default: ''
			},
			options: {
				type: Array,
				default () {
					return [];
				}
			},
			disabled: {
				type: Boolean,
				default: false
			},
			renderOnChange: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			showField: true
		}),
		computed: {
			getCard: {
				get () {
					return this.value;
				},
				set (newVal) {
					this.$emit('input', newVal);
				}
			}
		},
		watch: {
			value () {
				if (this.renderOnChange) {
					this.showField = false;
					this.$nextTick(() => {
						this.showField = true;
					});
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='card-select'] {
		[data-component='select-field'] {
			width: 140px;
		}
	}

</style>
