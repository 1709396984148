var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-component":"edit-bid-hints-modal"}},[_c('div',{attrs:{"data-element":"bids"}},[_c('dynamic-table',{attrs:{"columns":_vm.columns,"data":_vm.getBidHints,"activeRow":_vm.activeItem,"max-height":"376px"},scopedSlots:_vm._u([{key:"bid",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('bid-preview',{attrs:{"bid":_vm.formatBid(row.bid, index)}})]}},{key:"hint",fn:function(ref){
var cell = ref.cell;
return [_c('div',{attrs:{"data-element":"hint-wrapper"},domProps:{"innerHTML":_vm._s(cell)}})]}},{key:"action",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('btn',{attrs:{"text":"Edit","size":"sm"},nativeOn:{"click":function($event){return _vm.handleEdit(row, index)}}})]}}])})],1),(_vm.getShowField)?_c('div',{attrs:{"data-element":"edit-hint"}},[_c('validation-observer',{ref:"form",attrs:{"data-element":"form","tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSave($event)}}},[(_vm.getReusableItemsVisibility)?_c('select-field',{attrs:{"name":"Saved bid hints","searchable":"","htmlOptions":"","searchIsLoading":_vm.reusableItemsLoading,"options":_vm.getReusableBidHintsOptions},on:{"input":_vm.selectReusableBidHint,"searchChange":_vm.handleSearchChange}}):_vm._e(),_c('wysiwyg-field',{attrs:{"name":"Hint","type":"text","hasEditOptions":false,"hasListControl":false,"hasLinkControl":false,"value":_vm.getActiveItemHint},on:{"input":_vm.handleHintInput}}),_c('actions',{attrs:{"actions":_vm.getActions,"isWaiting":_vm.isWaiting},on:{"actionClick":_vm.onActionClick}})],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }