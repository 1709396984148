<template>
	<div
		data-component="dynamic-table"
		:style="wrapperStyle"
	>
		<table>
			<thead>
				<tr>
					<th
						v-for="(column, index) in columns"
						:key="index"
						:data-element="'table-column-' + column.key"
						data-column-head
						data-column
					>
						{{ column.label }}
					</th>
				</tr>
			</thead>
			<tbody>
				<template v-if="data && data.length">
					<tr
						v-for="(row, index) in data"
						:key="index"
						:data-active="activeRow === index"
						@mouseenter="onRowHover(index)"
						@mouseleave.self="onRowHover(null)"
					>
						<td
							v-for="(column, indexColumn) in columns"
							:key="indexColumn"
							:data-element="'table-column-' + column.key"
							data-column-body
							data-column
						>
							<slot
								:name="column.key"
								:column="column"
								:row="row"
								:cell="row[column.key]"
								:index="index"
							/>
							<template v-if="!$scopedSlots[column.key]">
								<slot
									name="cell"
									:column="column"
									:row="row"
									:index="index"
								/>
								<template v-if="!$scopedSlots.cell">
									{{ row[column.key] }}
								</template>
							</template>
						</td>
					</tr>
				</template>
				<tr v-else data-element="empty-table">
					<td :colspan="columns.length">
						<slot name="empty">
							Empty
						</slot>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>

	export default {
		components: {},
		props: {
			columns: {
				type: Array,
				required: true
			},
			data: {
				type: Array,
				default: () => ([])
			},
			activeRow: {
				type: Number,
				default: undefined
			},
			maxHeight: {
				type: String,
				default: 'auto'
			},
			overflow: {
				type: String,
				default: 'auto'
			}
		},
		computed: {
			wrapperStyle () {
				return {
					overflow: this.overflow,
					maxHeight: this.maxHeight
				};
			}
		},
		methods: {
			onRowHover (index) {
				this.$emit('rowHovered', index);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='dynamic-table'] {
		table {
			table-layout: fixed;
			width: 100%;
			border-collapse: collapse;
			tr {
				th, td {
					text-align: left;
					white-space: nowrap;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
				}
			}

			thead {
				tr {
					th {
						padding: rem(10) rem(16);
						font-size: rem(16);
						font-weight: bold;
						color: $c-brand-blue;
						line-height: normal;

						&:last-child {
							text-align: right;
						}
					}
				}
			}

			tbody {
				tr {
					transition: 0.3s ease-in-out;

					&:not([data-element='empty-table']):hover, &[data-active] {
						background-color: $c-brand-grey-lighter-6;
					}

					td {
						padding: rem(6) rem(16);
						font-size: rem(16);
						line-height: normal;

						&:last-child {
							text-align: right;
						}
					}

					&[data-element='empty-table'] {
						td {
							width: 100%;
							font-size:rem(18);
							color: $c-brand-grey;
							text-align:center;
						}
					}
				}
			}
		}
  }

</style>
