<template>
	<component
		data-component="list-view-row"
		:is="getComponent"
		:data-disabled="disabled"
		:data-dense="dense"
		v-bind="getProps"
		@click="click"
	>
		<slot />
		<icon
			v-if="getHasSomeKindOfAction"
			icon="chevron-right"
		/>
	</component>
</template>

<script>

	import Icon    from '@/components/ui/Icon';
	import action  from '@/mixins/action';

	export default {
		components: {
			Icon
		},
		mixins: [action],
		props: {
			text: {
				type: String,
				default: ''
			},
			dense: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getHasSomeKindOfAction () {
				if (!this.actionId &&
						!this.action &&
						!this.route &&
						!this.url &&
						this.type !== 'submit') {
					return false;
				}
				return true;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='list-view-row'] {
		display:flex;
		align-items:center;
		width:100%;
		padding:rem(12) 0;
		border:0 none;
		border-bottom: 1px solid $c-brand-grey-lighter-2;
		outline:0 none;
		background:transparent;
		font-size:rem(18);
		line-height:1;
		text-align:left;
		color:$c-text;
		user-select:none;
		cursor: pointer;
		&[data-disabled] {
			cursor:default;
			color:$c-brand-grey-lighter-2;
		}
		&[data-dense] {
			padding:rem(7) 0;
		}
		[data-component='icon'] {
			width:auto;
			height:rem(18);
			margin:auto 0 auto auto;
		}
	}

</style>
