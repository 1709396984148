<template>
	<div data-component="edit-deal-recording-modal">
		<div
			v-if="getShowDealRecordingVideo"
			data-element="deal-recording-video"
		>
			<video-viewer :src="getDealRecordingVideoSrc" />
			<actions
				:actions="getDealRecordingActions"
				:isWaiting="isWaiting"
				@actionClick="onActionClick"
			/>
		</div>
		<div
			v-else
			data-element="add-deal-recording"
		>
			<p data-element="explanation">
				Start screen recording of the deal run-through or upload a prepared file.
			</p>
			<actions
				:actions="getAddDealRecordingActions"
				:isWaiting="isWaiting"
				@actionClick="onActionClick"
			/>
			<div
				v-if="fileUploadIsVisible"
				data-element="file-upload-form"
			>
				<validation-observer
					data-element="form"
					tag="form"
					ref="form"
					@submit.prevent="uploadForm"
				>
					<upload
						fileType="mp4"
						:rules="rules"
						:form.sync="formData"
					/>
					<actions
						:actions="getUploadActions"
						:isWaiting="isWaiting"
						@actionClick="onActionClick"
					/>
				</validation-observer>
			</div>
		</div>
	</div>
</template>

<script>

	import Actions from '@/components/ui/Actions';
	import VideoViewer from '@/components/uploads/VideoViewer';
	import Upload from '@/components/uploads/Upload';
	import actionClick from '@/mixins/actionClick';
	import routeParams from '@/mixins/routeParams';
	import api from '@/services/api';
	import { dealRecordingType }  from '@/consts';
	import { v4 as uuid } from 'uuid';

	export default {
		components: {
			Actions,
			VideoViewer,
			Upload
		},
		mixins: [actionClick, routeParams],
		props: {
			deal: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			formData: undefined,
			fileUploadIsVisible: false,
			rules: {
				required: true,
				video: 'mp4|webm',
				size: 512000
			},
			isWaiting: undefined
		}),
		computed: {
			getDealMedia () {
				return this.deal?.media || [];
			},
			getDealRecording () {
				return this.getDealMedia.find(item => item.config?.type === dealRecordingType);
			},
			getPreviewBlob () {
				return this.$store.getters['screenRecorder/getPreviewBlob'];
			},
			getShowDealRecordingVideo () {
				return !!(this.getDealRecording || this.getPreviewBlob);
			},
			getDealRecordingVideoSrc () {
				if (this.getDealRecording) {
					return this.getDealRecording.url;
				}

				if (this.getPreviewBlob) {
					return URL.createObjectURL(this.getPreviewBlob);
				}

				return undefined;
			},
			getDealRecordingActions () {
				return {
					primary: [
						(
							this.getPreviewBlob ?
								{
									text: 'Save',
									btnId: 'uploadBlob',
									actionId: 'uploadBlob'
								} : false
						),
						(
							this.getPreviewBlob ?
								{
									text: 'Record again',
									btnId: 'recordAgain',
									actionId: 'recordAgain'
								} : false
						),
						(
							this.getDealRecording ?
								{
									text: 'Download',
									url: this.getDealRecording.url
								} : false
						)

					].filter(Boolean),
					secondary: [
						(
							this.getPreviewBlob ?
								{
									text: 'Cancel',
									actionId: 'cancel'
								} : false
						),
						(
							this.getDealRecording ?
								{
									text: 'Delete',
									colour: 'red',
									btnId: 'delete',
									actionId: 'deleteDealRecording'
								} : false
						)
					].filter(Boolean)
				};
			},
			getAddDealRecordingActions () {
				return {
					primary: [
						{
							text: 'Record a deal run-through',
							actionId: 'recordDealRecording'
						}
					],
					secondary: [
						{
							text: this.fileUploadIsVisible ? 'Hide' : 'Upload file',
							actionId: 'toggleFileUpload'
						}
					]
				};
			},
			getUploadActions () {
				return {
					primary: [
						{
							text: 'Save',
							btnId: 'uploadForm',
							type: 'submit'
						}
					]
				};
			}
		},
		created () {
		},
		methods: {
			cancel () {
				this.$store.dispatch('screenRecorder/clearPreviewBlob');
				this.$emit('close');
			},
			recordDealRecording () {
				this.$emit('recordDealRecording');
			},
			recordAgain () {
				this.$store.dispatch('screenRecorder/clearPreviewBlob');
				this.$emit('recordDealRecording');
			},
			toggleFileUpload () {
				this.fileUploadIsVisible = !this.fileUploadIsVisible;
			},
			async uploadRecordingFile (formData) {
				const config = {
					type: dealRecordingType,
					uploadUuid: uuid()
				};

				formData.append('config', JSON.stringify(config));

				const response = await api.deals.createMediaByDealId({
					dealId: this.deal.id,
					media: formData
				});

				if (response) {
					this.deal.media = [...this.deal.media, response];

					this.isWaiting = undefined;
					this.$store.commit('ui/showNotification', {
						notification: 'Upload complete 👍'
					});
				} else {
					console.error('Something went wrong. Empty response received.');
				}
			},
			async uploadForm () {
				const isValid = await this.$refs.form.validate();
				if (!isValid) {
					return;
				}
				this.isWaiting = 'uploadForm';
				await this.uploadRecordingFile(this.formData);
			},
			async uploadBlob () {
				if (this.getPreviewBlob) {
					this.isWaiting = 'uploadBlob';

					const name = `${uuid()}-recording.webm`;
					const media = new File([this.getPreviewBlob], name, {
						type: 'video/webm'
					});

					const formData = new FormData();
					formData.append('media', media);

					await this.uploadRecordingFile(formData);
					this.$store.dispatch('screenRecorder/clearPreviewBlob');
				}
			},
			async deleteDealRecording () {
				if (this.getDealRecording && confirm('Are you sure?')) {
					this.isWaiting = 'delete';
					const response = await api.deals.deleteMediaByDealIdAndMediaId({
						dealId: this.deal.id,
						mediaId: this.getDealRecording.id
					});

					if (!response) {
						return false;
					}

					this.deal.media = this.deal.media.filter((item) => {
						return item.id !== this.getDealRecording.id;
					});

					this.isWaiting = undefined;
					this.$store.commit('ui/showNotification', {
						notification: 'Successfully deleted.'
					});
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='edit-deal-recording-modal'] {
		[data-element='deal-recording-video'] {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}
		[data-element='add-deal-recording'] {
			[data-element='explanation'] {
				font-size: 1.125rem;
				line-height: 1.5;
			}
			[data-element='file-upload-form'] {
				form {
					margin-bottom: 0;
					margin-top: rem(10);
				}
				[data-component='actions'] {
					width: 100%;
					padding-top: 0;
				}
			}
		}
		[data-component='actions'] {
			width: 100%;
			padding-bottom: 0;
		}
	}
</style>
